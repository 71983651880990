import * as React from "react";

type ResetAccountPasswordProps = {
  //
};

const ResetAccountPassword: React.FC<ResetAccountPasswordProps> = () => {
  // TODO: get reset token account + reset email
  return (
    <div>
      <div>Email:</div>
    </div>
  );
};

export default ResetAccountPassword;
