import { gql } from "@apollo/client";
import {
  chatFields,
  companyFields,
  expenseFields,
  historicalUserStatisticsFields,
  inspectionFields,
  landlordFields,
  messageFields,
  officeFields,
  paginationFields,
  palaceFields,
  propertyFields,
  propertyManagerFields,
  supplierFields,
  tenancyFields,
  tenantFields,
  userFields,
  workOrderFields,
} from "./fragments";

export const USER = gql`
  query User {
    user {
      ...UserFields
      tenant {
        ...TenantFields
        property {
          ...PropertyFields
        }
      }
      landlord {
        ...LandlordFields
      }
      propertyManager {
        ...PropertyManagerFields
      }
    }
  }
  ${userFields}
  ${propertyFields}
  ${tenantFields}
  ${landlordFields}
  ${propertyManagerFields}
`;

export const COMPANY = gql`
  query Company {
    company {
      ...CompanyFields
      offices {
        ...OfficeFields
        palace {
          ...PalaceFields
        }
      }
    }
  }
  ${companyFields}
  ${officeFields}
  ${palaceFields}
`;

export const OFFICES = gql`
  query Offices {
    offices {
      ...OfficeFields
      palace {
        ...PalaceFields
      }
    }
  }
  ${officeFields}
  ${palaceFields}
`;

export const PROPERTIES = gql`
  query Properties($input: PropertiesInput) {
    properties(input: $input) {
      ...PropertyFields
    }
  }
  ${propertyFields}
`;

export const PROPERTY = gql`
  query Property($id: ID!) {
    property(id: $id) {
      ...PropertyFields
      propertyManager {
        ...PropertyManagerFields
        user {
          ...UserFields
        }
      }
      landlord {
        ...LandlordFields
        user {
          ...UserFields
        }
      }
      workOrders {
        ...WorkOrderFields
        property {
          ...PropertyFields
        }
        expense {
          ...ExpenseFields
        }
        supplier {
          ...SupplierFields
        }
        tenancy {
          ...TenancyFields
        }
        propertyManager {
          ...PropertyManagerFields
          user {
            ...UserFields
          }
        }
      }
      tenancies {
        ...TenancyFields
        tenants {
          ...TenantFields
        }
      }
    }
  }
  ${propertyFields}
  ${userFields}
  ${propertyManagerFields}
  ${landlordFields}
  ${workOrderFields}
  ${tenancyFields}
  ${tenantFields}
  ${expenseFields}
  ${supplierFields}
  ${tenancyFields}
  ${propertyManagerFields}
  ${userFields}
`;

export const USERS = gql`
  query Users($input: UsersInput, $pagination: PaginationInput) {
    users(input: $input, pagination: $pagination) {
      users {
        ...UserFields
        landlord {
          ...LandlordFields
        }
        propertyManager {
          ...PropertyManagerFields
        }
        tenant {
          ...TenantFields
        }
      }
      pagination {
        ...PaginationFields
      }
    }
  }
  ${userFields}
  ${landlordFields}
  ${propertyManagerFields}
  ${tenantFields}
`;

export const INVITE = gql`
  query Invite($token: String!) {
    invite(token: $token) {
      ...UserFields
    }
  }
  ${userFields}
`;

export const INSPECTIONS = gql`
  query Inspections($propertyId: ID, $pagination: PaginationInput) {
    inspections(propertyId: $propertyId, pagination: $pagination) {
      inspections {
        ...InspectionFields
        property {
          ...PropertyFields
        }
        propertyManager {
          ...PropertyManagerFields
          user {
            ...UserFields
          }
        }
      }
      pagination {
        ...PaginationFields
      }
    }
  }
  ${inspectionFields}
  ${propertyFields}
  ${propertyManagerFields}
  ${userFields}
  ${paginationFields}
`;

export const WORK_ORDERS = gql`
  query WorkOrders($pagination: PaginationInput, $propertyId: ID) {
    workOrders(pagination: $pagination, propertyId: $propertyId) {
      workOrders {
        ...WorkOrderFields
        property {
          ...PropertyFields
        }
        expense {
          ...ExpenseFields
        }
        supplier {
          ...SupplierFields
        }
        tenancy {
          ...TenancyFields
        }
        propertyManager {
          ...PropertyManagerFields
          user {
            ...UserFields
          }
        }
      }
      pagination {
        ...PaginationFields
      }
    }
  }
  ${workOrderFields}
  ${propertyFields}
  ${expenseFields}
  ${supplierFields}
  ${tenancyFields}
  ${propertyManagerFields}
  ${userFields}
  ${paginationFields}
`;

export const LANDLORD_HISTORY = gql`
  query LandlordHistory {
    landlordHistory {
      ...HistoricalUserStatisticsFields
    }
  }
  ${historicalUserStatisticsFields}
`;

export const PROPERTY_HISTORY = gql`
  query PropertyHistory {
    propertyHistory {
      ...HistoricalUserStatisticsFields
    }
  }
  ${historicalUserStatisticsFields}
`;

export const BALANCE_HISTORY = gql`
  query BalanceHistory {
    balanceHistory {
      ...HistoricalUserStatisticsFields
    }
  }
  ${historicalUserStatisticsFields}
`;

export const CHATS = gql`
  query Chats {
    chats {
      ...ChatFields
      messages(first: 1) {
        ...MessageFields
      }
      users {
        ...UserFields
      }
    }
  }
  ${messageFields}
  ${chatFields}
  ${userFields}
`;

export const MESSAGES = gql`
  query Messages($chatId: ID!) {
    messages(chatId: $chatId) {
      ...MessageFields
      user {
        ...UserFields
      }
    }
  }
  ${messageFields}
  ${userFields}
`;

export const OFFICE = gql`
  query Office {
    office {
      ...OfficeFields
    }
  }
  ${officeFields}
`;
