import Input from "c/Input";
import { useFormik } from "formik";
import * as React from "react";
import Button from "ui/Button";

type RecoverAccountProps = {};

const RecoverAccount: React.FC<RecoverAccountProps> = () => {
  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: () => {
      console.log("TODO:");
    },
  });

  return (
    <form className="flex flex-col items-center justify-center">
      <Input
        value={values.email}
        containerClassName="mb-4"
        name="email"
        placeholder="email"
      />
      <Button label="submit" disabled={isSubmitting} />
    </form>
  );
};

export default RecoverAccount;
